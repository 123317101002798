import React from 'react'
import InViewMonitor from 'react-inview-monitor'

const LuggageSetsHeader = ({ title }) => {
  return (
    <InViewMonitor
      classNameNotInView="hide"
      classNameInView={`show`}
      repeatOnInView={true}
      toggleClassNameOnInView={true}
    >
      <div
        style={{
          maxWidth: 900,
          margin: '0 auto',
          position: 'relative',
          padding: '0 16px',
        }}
      >
        <div
          id={`banner-${title}`}
          style={{ position: 'absolute', top: -160, height: 1 }}
        />
        <svg id="Layer_1" viewBox="0 0 2226.18 1286">
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html: `
              .cls-1{
                transition: fill 1s;
                fill:#fff3db;
              }
              .cls-2{
                fill:#6f714a;
              }
              .hide .cls-1{
                fill: #fff;
              }
              .cls-3{
                fill:none;
                stroke:#000; 
                stroke-miterlimit:10;
                stroke-width:4px;
              }
            `,
              }}
            />
          </defs>
          <g>
            <path
              class="cls-1"
              d="M1636.1,1033.76c-20.31,13.23-35.15-36.76-59.39-36.76s-24.24,40-48.47,40-24.24-40-48.48-40c-24.23,0-24.23,40-48.47,40-24.24,0-24.24-40-48.47-40-24.24,0-24.24,40-48.48,40s-24.24-40-48.47-40c-24.24,0-24.24,40-48.47,40-24.24,0-24.24-40-48.47-40s-24.24,40-48.47,40-24.24-40-48.47-40c-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40s-17.26-23.53-37.57-36.76c-13.23-8.61-30.91,.98-30.91-14.81s40-15.78,40-31.57-40-15.78-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.58-40-15.79-40-31.57,40-15.79,40-31.58-44.15-39.72-30.92-48.34c20.31-13.23,35.15,36.76,59.39,36.76s24.24-40,48.47-40,24.24,40,48.48,40,24.23-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,17.26,23.53,37.57,36.76c13.23,8.61,30.91-.98,30.91,14.81s-40,15.78-40,31.57c0,15.78,40,15.78,40,31.57s-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57,0,15.78,40,15.78,40,31.57,0,15.79-40,15.79-40,31.57s40,15.78,40,31.57-40,15.79-40,31.57c0,15.79,40,15.79,40,31.57s-40,15.79-40,31.57,40,15.79,40,31.57-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57s40,15.79,40,31.57c0,15.79-40,15.79-40,31.58s40,15.79,40,31.57-44.01,23.04-38.94,37.99c8.46,24.96,43.09,33.3,29.86,41.92Z"
            />
            <path
              class="cls-3"
              d="M1636.1,1033.76c-20.31,13.23-35.15-36.76-59.39-36.76s-24.24,40-48.47,40-24.24-40-48.48-40c-24.23,0-24.23,40-48.47,40-24.24,0-24.24-40-48.47-40-24.24,0-24.24,40-48.48,40s-24.24-40-48.47-40c-24.24,0-24.24,40-48.47,40-24.24,0-24.24-40-48.47-40s-24.24,40-48.47,40-24.24-40-48.47-40c-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40s-17.26-23.53-37.57-36.76c-13.23-8.61-30.91,.98-30.91-14.81s40-15.78,40-31.57-40-15.78-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.58-40-15.79-40-31.57,40-15.79,40-31.58-44.15-39.72-30.92-48.34c20.31-13.23,35.15,36.76,59.39,36.76s24.24-40,48.47-40,24.24,40,48.48,40,24.23-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,17.26,23.53,37.57,36.76c13.23,8.61,30.91-.98,30.91,14.81s-40,15.78-40,31.57c0,15.78,40,15.78,40,31.57s-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57,0,15.78,40,15.78,40,31.57,0,15.79-40,15.79-40,31.57s40,15.78,40,31.57-40,15.79-40,31.57c0,15.79,40,15.79,40,31.57s-40,15.79-40,31.57,40,15.79,40,31.57-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57s40,15.79,40,31.57c0,15.79-40,15.79-40,31.58s40,15.79,40,31.57-44.01,23.04-38.94,37.99c8.46,24.96,43.09,33.3,29.86,41.92Z"
            />
          </g>
          <image
            width="4443"
            height="2946"
            transform="translate(584.18 333.19) scale(.24)"
            xlinkHref="/section1/wiggle.png"
          />
          <image
            width="3918"
            height="3918"
            transform="translate(184.18 686) scale(.15)"
            xlinkHref="/section1/spike.png"
          />
          <path
            class="cls-2"
            d="M0,152.93v359.84H71.15v-90.21h-30.34V152.93H0Z"
          />
          <path
            class="cls-2"
            d="M73.19,153.94v231.11c.42,30.92,.84,42.57,3.14,57.78,.84,6.59,3.56,22.81,9.63,38.01,14.23,35.98,33.69,36.49,40.39,36.49,14.86,0,27.83-8.62,37.87-35.98,9.83-26.86,11.09-57.78,11.3-70.45,.42-8.62,.42-17.23,.42-26.35V153.94h-39.97v236.17c-.21,11.15,0,23.31-4.18,30.92-2.51,4.56-4.81,4.56-6.07,4.56-11.09,0-10.67-30.41-10.88-35.48V153.94h-41.64Z"
          />
          <path
            class="cls-2"
            d="M291.92,235.54c-1.05-6.08-2.3-12.67-3.98-19.77-1.46-6.59-5.86-24.33-12.55-38.52-10.46-21.29-22.6-26.86-25.74-28.38-2.93-1.01-5.86-1.52-8.79-1.52-12.97,0-20.51,7.6-28.46,19.26-6.07,9.63-14.23,25.85-20.51,61.32-5.02,28.89-7.53,58.28-7.53,106.94s3.98,88.69,9.42,114.03c2.72,13.18,6.49,24.33,7.95,28.38,4.6,13.18,8.37,19.77,11.09,23.82,6.28,9.63,15.07,18.25,26.16,18.25,18.83,0,30.34-16.22,38.29-35.98,6.91-17.74,11.72-39.53,14.23-79.57,1.05-19.76,1.88-39.02,2.09-58.79v-40.04h-54.4v76.02h12.76c.21,17.23,.21,35.48-5.65,46.12-2.72,5.07-5.23,5.07-6.28,5.07s-5.44,.51-8.79-11.15c-2.93-9.12-4.19-22.3-4.81-33.45-1.25-21.29-1.46-40.04-1.46-47.13,0-34.97,1.88-59.3,2.93-69.94,1.46-14.19,3.77-23.31,6.9-29.9,2.93-5.58,5.44-5.58,6.49-5.58,8.37,0,10.67,22.81,11.93,35.48l38.71-34.97Z"
          />
          <path
            class="cls-2"
            d="M405.19,235.54c-1.05-6.08-2.3-12.67-3.98-19.77-1.46-6.59-5.86-24.33-12.56-38.52-10.46-21.29-22.6-26.86-25.74-28.38-2.93-1.01-5.86-1.52-8.79-1.52-12.97,0-20.51,7.6-28.46,19.26-6.07,9.63-14.23,25.85-20.51,61.32-5.02,28.89-7.53,58.28-7.53,106.94s3.98,88.69,9.42,114.03c2.72,13.18,6.49,24.33,7.95,28.38,4.6,13.18,8.37,19.77,11.09,23.82,6.28,9.63,15.07,18.25,26.16,18.25,18.83,0,30.34-16.22,38.29-35.98,6.91-17.74,11.72-39.53,14.23-79.57,1.05-19.76,1.88-39.02,2.09-58.79v-40.04h-54.41v76.02h12.76c.21,17.23,.21,35.48-5.65,46.12-2.72,5.07-5.23,5.07-6.28,5.07s-5.44,.51-8.79-11.15c-2.93-9.12-4.18-22.3-4.81-33.45-1.26-21.29-1.46-40.04-1.46-47.13,0-34.97,1.88-59.3,2.93-69.94,1.47-14.19,3.77-23.31,6.91-29.9,2.93-5.58,5.44-5.58,6.49-5.58,8.37,0,10.67,22.81,11.93,35.48l38.71-34.97Z"
          />
          <path
            class="cls-2"
            d="M431.1,153.94l-29.92,358.82h41.01l2.72-46.63h26.16l2.72,46.63h41.01l-33.06-358.82h-50.64Zm25.32,95.79l9.62,139.88h-17.16l7.53-139.88Z"
          />
          <path
            class="cls-2"
            d="M618.65,235.54c-1.05-6.08-2.3-12.67-3.98-19.77-1.46-6.59-5.86-24.33-12.56-38.52-10.46-21.29-22.6-26.86-25.74-28.38-2.93-1.01-5.86-1.52-8.79-1.52-12.97,0-20.51,7.6-28.46,19.26-6.07,9.63-14.23,25.85-20.51,61.32-5.02,28.89-7.53,58.28-7.53,106.94s3.98,88.69,9.42,114.03c2.72,13.18,6.49,24.33,7.95,28.38,4.6,13.18,8.37,19.77,11.09,23.82,6.28,9.63,15.07,18.25,26.16,18.25,18.83,0,30.34-16.22,38.29-35.98,6.91-17.74,11.72-39.53,14.23-79.57,1.05-19.76,1.88-39.02,2.09-58.79v-40.04h-54.41v76.02h12.76c.21,17.23,.21,35.48-5.65,46.12-2.72,5.07-5.23,5.07-6.28,5.07s-5.44,.51-8.79-11.15c-2.93-9.12-4.18-22.3-4.81-33.45-1.26-21.29-1.46-40.04-1.46-47.13,0-34.97,1.88-59.3,2.93-69.94,1.47-14.19,3.77-23.31,6.91-29.9,2.93-5.58,5.44-5.58,6.49-5.58,8.37,0,10.67,22.81,11.93,35.48l38.71-34.97Z"
          />
          <path
            class="cls-2"
            d="M627.51,153.94v358.82h74.28v-84.64h-34.73v-57.78h31.39v-81.09h-30.97v-51.19h34.53v-84.13h-74.49Z"
          />
          <path
            class="cls-2"
            d="M1160.18,856.32v359.84h71.21v-90.21h-30.37v-269.62h-40.84Z"
          />
          <path
            class="cls-2"
            d="M1233.44,857.33v231.11c.42,30.92,.84,42.57,3.14,57.78,.84,6.59,3.56,22.81,9.63,38.01,14.24,35.98,33.72,36.49,40.42,36.49,14.87,0,27.86-8.62,37.91-35.98,9.84-26.86,11.1-57.78,11.31-70.45,.42-8.62,.42-17.23,.42-26.35v-230.6h-40v236.17c-.21,11.15,0,23.31-4.19,30.92-2.51,4.56-4.82,4.56-6.07,4.56-11.1,0-10.68-30.41-10.89-35.48v-236.17h-41.68Z"
          />
          <path
            class="cls-2"
            d="M1452.37,938.93c-1.05-6.08-2.3-12.67-3.98-19.77-1.47-6.59-5.86-24.33-12.57-38.52-10.47-21.29-22.62-26.86-25.76-28.38-2.93-1.01-5.86-1.52-8.8-1.52-12.99,0-20.52,7.6-28.48,19.26-6.07,9.63-14.24,25.85-20.52,61.32-5.03,28.89-7.54,58.28-7.54,106.94s3.98,88.69,9.42,114.03c2.72,13.18,6.49,24.33,7.96,28.38,4.61,13.18,8.38,19.77,11.1,23.82,6.28,9.63,15.08,18.25,26.18,18.25,18.85,0,30.37-16.22,38.33-35.98,6.91-17.74,11.73-39.53,14.24-79.57,1.05-19.76,1.89-39.02,2.09-58.79v-40.04h-54.45v76.02h12.78c.21,17.23,.21,35.48-5.65,46.12-2.72,5.07-5.24,5.07-6.28,5.07s-5.45,.51-8.8-11.15c-2.93-9.12-4.19-22.3-4.82-33.45-1.26-21.29-1.47-40.04-1.47-47.13,0-34.97,1.89-59.3,2.93-69.94,1.47-14.19,3.77-23.31,6.91-29.9,2.93-5.58,5.45-5.58,6.49-5.58,8.38,0,10.68,22.81,11.94,35.48l38.75-34.97Z"
          />
          <path
            class="cls-2"
            d="M1565.74,938.93c-1.05-6.08-2.3-12.67-3.98-19.77-1.47-6.59-5.87-24.33-12.57-38.52-10.47-21.29-22.62-26.86-25.76-28.38-2.93-1.01-5.86-1.52-8.8-1.52-12.99,0-20.53,7.6-28.48,19.26-6.07,9.63-14.24,25.85-20.53,61.32-5.03,28.89-7.54,58.28-7.54,106.94s3.98,88.69,9.43,114.03c2.72,13.18,6.49,24.33,7.96,28.38,4.61,13.18,8.38,19.77,11.1,23.82,6.28,9.63,15.08,18.25,26.18,18.25,18.85,0,30.37-16.22,38.33-35.98,6.91-17.74,11.73-39.53,14.24-79.57,1.05-19.76,1.88-39.02,2.09-58.79v-40.04h-54.45v76.02h12.78c.21,17.23,.21,35.48-5.66,46.12-2.72,5.07-5.24,5.07-6.28,5.07s-5.45,.51-8.8-11.15c-2.93-9.12-4.19-22.3-4.82-33.45-1.26-21.29-1.47-40.04-1.47-47.13,0-34.97,1.88-59.3,2.93-69.94,1.47-14.19,3.77-23.31,6.91-29.9,2.93-5.58,5.44-5.58,6.49-5.58,8.38,0,10.68,22.81,11.94,35.48l38.75-34.97Z"
          />
          <path
            class="cls-2"
            d="M1591.67,857.33l-29.95,358.82h41.05l2.72-46.63h26.18l2.72,46.63h41.05l-33.09-358.82h-50.68Zm25.34,95.79l9.63,139.88h-17.17l7.54-139.88Z"
          />
          <path
            class="cls-2"
            d="M1779.39,938.93c-1.05-6.08-2.3-12.67-3.98-19.77-1.47-6.59-5.86-24.33-12.57-38.52-10.47-21.29-22.62-26.86-25.76-28.38-2.93-1.01-5.86-1.52-8.8-1.52-12.99,0-20.53,7.6-28.48,19.26-6.07,9.63-14.24,25.85-20.53,61.32-5.03,28.89-7.54,58.28-7.54,106.94s3.98,88.69,9.42,114.03c2.72,13.18,6.49,24.33,7.96,28.38,4.61,13.18,8.38,19.77,11.1,23.82,6.28,9.63,15.08,18.25,26.18,18.25,18.85,0,30.37-16.22,38.33-35.98,6.91-17.74,11.73-39.53,14.24-79.57,1.05-19.76,1.89-39.02,2.09-58.79v-40.04h-54.45v76.02h12.78c.21,17.23,.21,35.48-5.65,46.12-2.72,5.07-5.24,5.07-6.28,5.07s-5.45,.51-8.8-11.15c-2.93-9.12-4.19-22.3-4.82-33.45-1.26-21.29-1.47-40.04-1.47-47.13,0-34.97,1.89-59.3,2.93-69.94,1.47-14.19,3.77-23.31,6.91-29.9,2.93-5.58,5.45-5.58,6.49-5.58,8.38,0,10.68,22.81,11.94,35.48l38.75-34.97Z"
          />
          <path
            class="cls-2"
            d="M1788.25,857.33v358.82h74.35v-84.64h-34.77v-57.78h31.42v-81.09h-31v-51.19h34.56v-84.13h-74.56Z"
          />
          <path
            class="cls-2"
            d="M1981.27,868.48c-10.26-10.14-16.76-16.22-28.27-16.22-29.74,0-39.79,53.72-42.94,78.05-2.3,17.74-2.51,30.92-2.51,38.52,0,43.59,9.64,68.93,14.45,80.58,4.4,10.14,8.59,17.74,10.47,20.78,5.45,10.14,13.19,21.29,13.19,40.04,0,15.71-5.24,20.78-7.33,22.3-.84,.51-2.09,1.52-4.4,1.52-4.61,0-8.8-4.56-10.26-6.59-8.59-9.63-13.61-28.38-15.08-33.45v113.02c2.09,2.03,4.4,4.06,6.49,6.08,10.47,7.6,19.06,9.12,22.41,9.12,1.68,0,17.59-.51,29.95-23.31,8.59-16.22,15.08-42.07,17.38-67.41,1.05-12.67,1.05-22.3,1.05-27.37,0-23.31-1.89-40.54-3.35-48.15-4.61-27.37-14.45-45.61-24.09-62.34-6.91-11.66-10.89-21.29-10.89-31.42-.21-14.19,5.45-19.26,6.49-19.77,1.68-1.52,2.93-1.52,3.56-1.52,6.49,0,11.73,8.62,14.45,13.68,3.77,7.1,6.28,14.19,9.22,23.31v-109.47Z"
          />
          <path
            class="cls-2"
            d="M1993.58,857.33v358.82h74.35v-84.64h-34.77v-57.78h31.42v-81.09h-31v-51.19h34.56v-84.13h-74.56Z"
          />
          <path
            class="cls-2"
            d="M2065.06,856.32v87.17h21.99v272.67h41.05v-272.67h22.41v-87.17h-85.45Z"
          />
          <path
            class="cls-2"
            d="M2221.57,868.48c-10.26-10.14-16.75-16.22-28.27-16.22-29.74,0-39.79,53.72-42.94,78.05-2.3,17.74-2.51,30.92-2.51,38.52,0,43.59,9.63,68.93,14.45,80.58,4.4,10.14,8.59,17.74,10.47,20.78,5.45,10.14,13.2,21.29,13.2,40.04,0,15.71-5.24,20.78-7.33,22.3-.84,.51-2.1,1.52-4.4,1.52-4.61,0-8.8-4.56-10.26-6.59-8.59-9.63-13.61-28.38-15.08-33.45v113.02c2.1,2.03,4.4,4.06,6.49,6.08,10.47,7.6,19.06,9.12,22.41,9.12,1.67,0,17.59-.51,29.95-23.31,8.59-16.22,15.08-42.07,17.38-67.41,1.05-12.67,1.05-22.3,1.05-27.37,0-23.31-1.89-40.54-3.35-48.15-4.61-27.37-14.45-45.61-24.08-62.34-6.91-11.66-10.89-21.29-10.89-31.42-.21-14.19,5.45-19.26,6.49-19.77,1.67-1.52,2.93-1.52,3.56-1.52,6.49,0,11.73,8.62,14.45,13.68,3.77,7.1,6.28,14.19,9.22,23.31v-109.47Z"
          />
          <image
            width="4455"
            height="4455"
            transform="translate(1337.18) scale(.17)"
            xlinkHref="/section1/spike2.png"
          />
          <image
            width="1000"
            height="1000"
            transform="translate(192.18 667) scale(.61)"
            xlinkHref="/section1/light.png"
          />
          <image
            width="1000"
            height="1000"
            transform="translate(1411.18 28) scale(.61)"
            xlinkHref="/section1/trunk-set.png"
          />
        </svg>
      </div>
    </InViewMonitor>
  )
}

const TravelBagHeader = ({ title }) => {
  return (
    <InViewMonitor
      classNameNotInView="hide"
      classNameInView={`show`}
      repeatOnInView={true}
      toggleClassNameOnInView={true}
    >
      <div
        style={{
          maxWidth: 900,
          margin: '0 auto',
          position: 'relative',
          padding: '0 16px',
        }}
      >
        <div
          id={`banner-${title}`}
          style={{ position: 'absolute', top: -160, height: 1 }}
        />
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1970 1389"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html: `
                .LuggageSetsHeader-1{
                  transition: fill 1s;
                  fill:#fff3db;
                }
                .hide .LuggageSetsHeader-1{
                  fill: #fff;
                }

                .LuggageSetsHeader-2{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:2px;}
              `,
              }}
            />
          </defs>
          <g>
            <path
              class="LuggageSetsHeader-1"
              d="M1540.92,1025.76c-20.31,13.23-35.15-36.76-59.39-36.76s-24.24,40-48.47,40-24.24-40-48.48-40c-24.23,0-24.23,40-48.47,40-24.24,0-24.24-40-48.47-40-24.24,0-24.24,40-48.48,40s-24.24-40-48.47-40c-24.24,0-24.24,40-48.47,40-24.24,0-24.24-40-48.47-40s-24.24,40-48.47,40-24.24-40-48.47-40c-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40s-17.26-23.53-37.57-36.76c-13.23-8.61-30.91,.98-30.91-14.81s40-15.78,40-31.57-40-15.78-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.58-40-15.79-40-31.57,40-15.79,40-31.58-44.15-39.72-30.92-48.34c20.31-13.23,35.15,36.76,59.39,36.76s24.24-40,48.47-40,24.24,40,48.48,40,24.23-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,17.26,23.53,37.57,36.76c13.23,8.61,30.91-.98,30.91,14.81s-40,15.78-40,31.57c0,15.78,40,15.78,40,31.57s-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57,0,15.78,40,15.78,40,31.57,0,15.79-40,15.79-40,31.57s40,15.78,40,31.57-40,15.79-40,31.57c0,15.79,40,15.79,40,31.57s-40,15.79-40,31.57,40,15.79,40,31.57-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57s40,15.79,40,31.57c0,15.79-40,15.79-40,31.58s40,15.79,40,31.57-44.01,23.04-38.94,37.99c8.46,24.96,43.09,33.3,29.86,41.92Z"
            />
            <path
              class="LuggageSetsHeader-2"
              d="M1540.92,1025.76c-20.31,13.23-35.15-36.76-59.39-36.76s-24.24,40-48.47,40-24.24-40-48.48-40c-24.23,0-24.23,40-48.47,40-24.24,0-24.24-40-48.47-40-24.24,0-24.24,40-48.48,40s-24.24-40-48.47-40c-24.24,0-24.24,40-48.47,40-24.24,0-24.24-40-48.47-40s-24.24,40-48.47,40-24.24-40-48.47-40c-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40s-17.26-23.53-37.57-36.76c-13.23-8.61-30.91,.98-30.91-14.81s40-15.78,40-31.57-40-15.78-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.58-40-15.79-40-31.57,40-15.79,40-31.58-44.15-39.72-30.92-48.34c20.31-13.23,35.15,36.76,59.39,36.76s24.24-40,48.47-40,24.24,40,48.48,40,24.23-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,17.26,23.53,37.57,36.76c13.23,8.61,30.91-.98,30.91,14.81s-40,15.78-40,31.57c0,15.78,40,15.78,40,31.57s-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57,0,15.78,40,15.78,40,31.57,0,15.79-40,15.79-40,31.57s40,15.78,40,31.57-40,15.79-40,31.57c0,15.79,40,15.79,40,31.57s-40,15.79-40,31.57,40,15.79,40,31.57-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57s40,15.79,40,31.57c0,15.79-40,15.79-40,31.58s40,15.79,40,31.57-44.01,23.04-38.94,37.99c8.46,24.96,43.09,33.3,29.86,41.92Z"
            />
          </g>
          <image
            width="4443"
            height="2946"
            transform="translate(489 325.19) scale(.24)"
            xlinkHref="/section1/wiggle.png"
          />
          <image
            width="318"
            height="185"
            transform="translate(0 236) scale(2)"
            xlinkHref="/section1/travel.png"
          />
          <image
            width="4405"
            height="4393"
            transform="translate(44 629) scale(.17)"
            xlinkHref="/section1/spike-blue.png"
          />
          <image
            width="3950"
            height="3894"
            transform="translate(1310) scale(.17)"
            xlinkHref="/section1/spike-yellow.png"
          />
          <image
            width="222"
            height="192"
            transform="translate(1352 817) scale(2)"
            xlinkHref="/section1/bags.png"
          />
          <image
            width="1000"
            height="1000"
            transform="translate(1325 28) scale(.57)"
            xlinkHref="/section1/bpk.png"
          />
          <image
            width="1000"
            height="1000"
            transform="translate(90 675) scale(.67)"
            xlinkHref="/section1/wkd.png"
          />
        </svg>
      </div>
    </InViewMonitor>
  )
}

const TravelAccessoriesHeader = ({ title }) => {
  return (
    <InViewMonitor
      classNameNotInView="hide"
      classNameInView={`show`}
      repeatOnInView={true}
      toggleClassNameOnInView={true}
    >
      <div
        style={{
          maxWidth: 900,
          margin: '0 auto',
          position: 'relative',
          padding: '0 16px',
        }}
      >
        <div
          id={`banner-${title}`}
          style={{ position: 'absolute', top: -160, height: 1 }}
        />
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2090 1353.94"
        >
          <defs>
            <style
              dangerouslySetInnerHTML={{
                __html: `
                .TravelAccessoriesHeader-1{
                  transition: fill 1s;
                  fill:#fff3db;
                }
                .hide .TravelAccessoriesHeader-1{
                  fill: #fff;
                }
                .TravelAccessoriesHeader-2{fill:#db8c81;}
                .TravelAccessoriesHeader-3{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:2px;}
                
              `,
              }}
            />
          </defs>
          <g>
            <path
              class="TravelAccessoriesHeader-1"
              d="M1540.78,1087.76c-20.31,13.23-35.15-36.76-59.39-36.76s-24.24,40-48.47,40-24.24-40-48.48-40c-24.23,0-24.23,40-48.47,40-24.24,0-24.24-40-48.47-40-24.24,0-24.24,40-48.48,40s-24.24-40-48.47-40c-24.24,0-24.24,40-48.47,40-24.24,0-24.24-40-48.47-40s-24.24,40-48.47,40-24.24-40-48.47-40c-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40s-17.26-23.53-37.57-36.76c-13.23-8.61-30.91,.98-30.91-14.81s40-15.78,40-31.57-40-15.78-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.58-40-15.79-40-31.57,40-15.79,40-31.58-44.15-39.72-30.92-48.34c20.31-13.23,35.15,36.76,59.39,36.76s24.24-40,48.47-40,24.24,40,48.48,40,24.23-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,17.26,23.53,37.57,36.76c13.23,8.61,30.91-.98,30.91,14.81s-40,15.78-40,31.57c0,15.78,40,15.78,40,31.57s-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57,0,15.78,40,15.78,40,31.57,0,15.79-40,15.79-40,31.57s40,15.78,40,31.57-40,15.79-40,31.57c0,15.79,40,15.79,40,31.57s-40,15.79-40,31.57,40,15.79,40,31.57-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57s40,15.79,40,31.57c0,15.79-40,15.79-40,31.58s40,15.79,40,31.57-44.01,23.04-38.94,37.99c8.46,24.96,43.09,33.3,29.86,41.92Z"
            />
            <path
              class="TravelAccessoriesHeader-3"
              d="M1540.78,1087.76c-20.31,13.23-35.15-36.76-59.39-36.76s-24.24,40-48.47,40-24.24-40-48.48-40c-24.23,0-24.23,40-48.47,40-24.24,0-24.24-40-48.47-40-24.24,0-24.24,40-48.48,40s-24.24-40-48.47-40c-24.24,0-24.24,40-48.47,40-24.24,0-24.24-40-48.47-40s-24.24,40-48.47,40-24.24-40-48.47-40c-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40s-24.24,40-48.48,40c-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40-24.24,0-24.24-40-48.48-40-24.24,0-24.24,40-48.48,40s-17.26-23.53-37.57-36.76c-13.23-8.61-30.91,.98-30.91-14.81s40-15.78,40-31.57-40-15.78-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.78,40-31.57-40-15.78-40-31.57,40-15.79,40-31.57-40-15.79-40-31.57,40-15.79,40-31.58-40-15.79-40-31.57,40-15.79,40-31.58-44.15-39.72-30.92-48.34c20.31-13.23,35.15,36.76,59.39,36.76s24.24-40,48.47-40,24.24,40,48.48,40,24.23-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.47-40,24.24,40,48.47,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,24.24,40,48.48,40,24.24-40,48.48-40,17.26,23.53,37.57,36.76c13.23,8.61,30.91-.98,30.91,14.81s-40,15.78-40,31.57c0,15.78,40,15.78,40,31.57s-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57,0,15.78,40,15.78,40,31.57,0,15.79-40,15.79-40,31.57s40,15.78,40,31.57-40,15.79-40,31.57c0,15.79,40,15.79,40,31.57s-40,15.79-40,31.57,40,15.79,40,31.57-40,15.78-40,31.57,40,15.78,40,31.57c0,15.79-40,15.79-40,31.57s40,15.79,40,31.57c0,15.79-40,15.79-40,31.58s40,15.79,40,31.57-44.01,23.04-38.94,37.99c8.46,24.96,43.09,33.3,29.86,41.92Z"
            />
          </g>
          <image
            width="4443"
            height="2946"
            transform="translate(488.86 387.19) scale(.24)"
            xlinkHref="/section1/wiggle.png"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M0,298.05v89.63H24.13v280.37h45.04V387.69h24.59v-89.63H0Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M98.66,298.05v370h43.21v-146.44l24.36,146.44h47.8l-32.63-154.25c1.15-1.56,7.12-7.3,11.49-15.63,12.87-25.53,13.79-67.22,13.79-77.65,0-53.68-12.41-84.94-19.53-97.97-12.64-21.89-27.58-23.45-32.63-23.97-2.53-.52-4.83-.52-7.35-.52h-48.49Zm43.21,81.82c3.68,.52,6.21,.52,9.88,3.65,9.88,9.38,10.57,33.35,10.57,40.13,0,17.2-3.91,35.96-11.26,42.73-3.91,3.13-7.81,2.61-9.19,2.61v-89.11Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M246.84,299.1l-32.86,368.96h45.04l2.99-47.94h28.73l2.99,47.94h45.04l-36.31-368.96h-55.62Zm27.81,98.49l10.57,143.83h-18.85l8.27-143.83Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M328.61,298.57l36.08,369.48h44.81l43.9-369.48h-45.96l-17.7,210.53-15.63-210.53h-45.5Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M457.37,299.1v368.96h81.58v-87.03h-38.15v-59.41h34.47v-83.38h-34.01v-52.63h37.92v-86.51h-81.81Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M557.86,298.05v370h78.14v-92.76h-33.32V298.05h-44.81Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M1049.83,912.75l-33.83,359.8h46.37l3.08-46.75h29.57l3.08,46.75h46.37l-37.38-359.8h-57.25Zm28.63,96.05l10.88,140.26h-19.4l8.52-140.26Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M1231.35,916.82c-12.3-7.62-17.51-8.64-24.13-8.64-10.17,0-34.78,5.08-52.76,52.85-5.68,15.25-9.46,31.51-10.65,37.61-8.04,38.62-8.52,79.79-8.52,93,0,49.29,5.44,102.66,21.06,140.77,17.27,41.67,37.85,45.23,47.55,45.23,14.19,0,22.71-8.13,28.39-13.21l2.13-103.16c-4.73,8.64-8.99,16.77-15.14,20.84-3.55,2.54-6.62,3.05-8.04,3.05-2.37,0-7.81-1.02-13.48-8.64-.95-1.02-2.6-3.05-4.5-7.11-11.83-23.89-11.12-74.71-11.12-78.26,0-28.46,2.6-67.08,14.67-84.36,5.68-8.64,11.59-9.66,14.19-9.66,11.36,0,18.22,15.75,20.82,21.34l-.47-101.64Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M1330.19,916.82c-12.3-7.62-17.51-8.64-24.13-8.64-10.17,0-34.78,5.08-52.76,52.85-5.68,15.25-9.46,31.51-10.65,37.61-8.04,38.62-8.52,79.79-8.52,93,0,49.29,5.44,102.66,21.05,140.77,17.27,41.67,37.85,45.23,47.55,45.23,14.19,0,22.71-8.13,28.39-13.21l2.13-103.16c-4.73,8.64-8.99,16.77-15.14,20.84-3.55,2.54-6.62,3.05-8.04,3.05-2.37,0-7.81-1.02-13.49-8.64-.95-1.02-2.6-3.05-4.49-7.11-11.83-23.89-11.12-74.71-11.12-78.26,0-28.46,2.6-67.08,14.67-84.36,5.68-8.64,11.59-9.66,14.19-9.66,11.36,0,18.22,15.75,20.82,21.34l-.47-101.64Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M1341.61,912.75v359.8h83.98v-84.87h-39.27v-57.93h35.49v-81.31h-35.01v-51.33h39.03v-84.36h-84.22Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M1510.71,923.93c-11.59-10.16-18.93-16.26-31.94-16.26-33.59,0-44.95,53.87-48.5,78.26-2.6,17.79-2.84,31-2.84,38.62,0,43.71,10.88,69.11,16.32,80.8,4.97,10.16,9.7,17.79,11.83,20.84,6.15,10.16,14.9,21.35,14.9,40.15,0,15.75-5.91,20.84-8.28,22.36-.95,.51-2.37,1.53-4.97,1.53-5.2,0-9.94-4.57-11.59-6.61-9.7-9.66-15.38-28.46-17.03-33.54v113.33c2.37,2.03,4.97,4.07,7.33,6.1,11.83,7.62,21.53,9.15,25.31,9.15,1.89,0,19.87-.51,33.83-23.38,9.7-16.26,17.03-42.18,19.63-67.59,1.18-12.7,1.18-22.36,1.18-27.44,0-23.38-2.13-40.66-3.79-48.28-5.2-27.44-16.32-45.74-27.21-62.51-7.81-11.69-12.3-21.34-12.3-31.51-.24-14.23,6.15-19.31,7.33-19.82,1.89-1.52,3.31-1.52,4.02-1.52,7.33,0,13.25,8.64,16.32,13.72,4.26,7.11,7.1,14.23,10.41,23.38v-109.77Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M1599.85,923.93c-11.59-10.16-18.93-16.26-31.94-16.26-33.59,0-44.95,53.87-48.5,78.26-2.6,17.79-2.84,31-2.84,38.62,0,43.71,10.88,69.11,16.32,80.8,4.97,10.16,9.7,17.79,11.83,20.84,6.15,10.16,14.9,21.35,14.9,40.15,0,15.75-5.91,20.84-8.28,22.36-.95,.51-2.37,1.53-4.97,1.53-5.2,0-9.94-4.57-11.59-6.61-9.7-9.66-15.38-28.46-17.03-33.54v113.33c2.37,2.03,4.97,4.07,7.33,6.1,11.83,7.62,21.53,9.15,25.31,9.15,1.89,0,19.87-.51,33.83-23.38,9.7-16.26,17.03-42.18,19.64-67.59,1.18-12.7,1.18-22.36,1.18-27.44,0-23.38-2.13-40.66-3.78-48.28-5.21-27.44-16.32-45.74-27.21-62.51-7.81-11.69-12.3-21.34-12.3-31.51-.24-14.23,6.15-19.31,7.33-19.82,1.89-1.52,3.31-1.52,4.02-1.52,7.33,0,13.25,8.64,16.32,13.72,4.26,7.11,7.1,14.23,10.41,23.38v-109.77Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M1669.35,907.16c-7.57,0-22.71,2.03-37.62,29.48-26.5,49.3-26.26,136.71-26.26,157.54,0,69.12,8.04,105.71,15.61,129.59,15.85,48.79,36.67,55.39,47.08,55.39,7.81,0,22.95-3.05,37.62-32.02,26.5-51.33,26.5-134.67,26.5-157.03,0-79.79-11.36-120.44-22.71-145.34-15.61-34.05-31.46-37.61-40.22-37.61Zm-.71,91.48c2.37,.51,6.15,2.54,9.46,12.7,2.37,7.11,4.26,16.26,5.44,34.56,.71,9.15,1.66,27.44,1.66,51.84,0,21.34-.71,49.8-4.97,70.13-3.31,15.76-7.81,20.33-11.59,20.84-2.13,0-6.39-2.54-9.46-12.2-3.78-11.69-4.97-29.48-5.2-32.52-1.42-19.82-1.42-40.15-1.42-46.25,0-21.85,.95-65.56,6.15-84.87,3.08-11.18,7.33-14.23,9.94-14.23Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M1737.43,911.74v360.82h44.47v-142.8l25.08,142.8h49.21l-33.59-150.43c1.18-1.53,7.33-7.12,11.83-15.25,13.25-24.9,14.19-65.56,14.19-75.72,0-52.34-12.77-82.84-20.11-95.54-13.01-21.34-28.39-22.87-33.59-23.38-2.6-.51-4.97-.51-7.57-.51h-49.92Zm44.47,79.79c3.79,.51,6.39,.51,10.17,3.56,10.17,9.15,10.88,32.52,10.88,39.13,0,16.77-4.02,35.07-11.59,41.67-4.02,3.05-8.04,2.54-9.46,2.54v-86.9Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M1855.66,912.75v359.8h45.66v-359.8h-45.66Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M1915.7,912.75v359.8h83.98v-84.87h-39.27v-57.93h35.48v-81.31h-35.01v-51.33h39.03v-84.36h-84.22Z"
          />
          <path
            class="TravelAccessoriesHeader-2"
            d="M2084.8,923.93c-11.59-10.16-18.93-16.26-31.94-16.26-33.59,0-44.95,53.87-48.5,78.26-2.6,17.79-2.84,31-2.84,38.62,0,43.71,10.88,69.11,16.32,80.8,4.97,10.16,9.7,17.79,11.83,20.84,6.15,10.16,14.9,21.35,14.9,40.15,0,15.75-5.91,20.84-8.28,22.36-.95,.51-2.37,1.53-4.97,1.53-5.2,0-9.94-4.57-11.59-6.61-9.7-9.66-15.38-28.46-17.03-33.54v113.33c2.37,2.03,4.97,4.07,7.33,6.1,11.83,7.62,21.53,9.15,25.31,9.15,1.89,0,19.87-.51,33.83-23.38,9.7-16.26,17.03-42.18,19.64-67.59,1.18-12.7,1.18-22.36,1.18-27.44,0-23.38-2.13-40.66-3.79-48.28-5.2-27.44-16.32-45.74-27.2-62.51-7.81-11.69-12.3-21.34-12.3-31.51-.24-14.23,6.15-19.31,7.33-19.82,1.89-1.52,3.31-1.52,4.02-1.52,7.33,0,13.25,8.64,16.32,13.72,4.26,7.11,7.1,14.23,10.41,23.38v-109.77Z"
          />
          <image
            width="4230"
            height="4212"
            transform="translate(1268.86 25) scale(.19)"
            xlinkHref="/section1/spike-purple.png"
          />
          <image
            width="4118"
            height="4078"
            transform="translate(147.86 740) scale(.15)"
            xlinkHref="/section1/spike-cream.png"
          />
          <image
            width="1400"
            height="1400"
            transform="translate(1355.86) scale(.45)"
            xlinkHref="/section1/toy.png"
          />
          <image
            width="1000"
            height="1000"
            transform="translate(116.86 760) scale(.55)"
            xlinkHref="/section1/me.png"
          />
        </svg>
      </div>
    </InViewMonitor>
  )
}

export { LuggageSetsHeader, TravelBagHeader, TravelAccessoriesHeader }
